import React from 'react'
import Layout from '../../layouts/layout'

class About extends React.Component {
  render () {
    return (
      <Layout>
        {/* Page Header */}
        <header className="masthead about">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="page-heading">
                  <h1>NLP <span style={{color:'#FFB900'}}>Champs</span></h1>
                  <span className="subheading">All About Us and Our "Inconcievable" Natural Language Processing Techniques.</span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <p>NLP Champs was started by two programmers who were, well, frankly, <i>inexperienced</i> when it came to programming natural language processing algorithms, and had trouble building this experience since they found that on stackoverflow and the web at large, there was no NLP "mecca", no undisputed source of NLP techniques and tips.</p>
              <p>This blog is a hope to fill that gap, to be filled with an NLP getting started page, real world applications and great algorithms from guest posters around the world, and any other resources we could think of. Enjoy!</p>
              <p>Think you've got a great NLP algorithm or a neat real world application that you'd like to share? Please check out the contact page and send us an email! That's why we're building this community - to share anything & everything NLP.</p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default About;
